<div
    class="main-container pservices text-justify">

    <!--CONTENEDOR DE COLUMNAS-->
    <div
        class="container">

        <!--INICIO DEL RENGLON-->
        <div
            class="row">

            <!--INICIO DE LA COLUMNA 1-->
            <div
                class="col">

                <!--CONTENEDIR DE REQUISITOS PARA LA OBTENCION DE GRADO-->
                <div class="container-requisitos-obtencion-grado">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item mt-3">
                            <h2 class="text-title mt-3 mb-3">
                                Requisitos para la obtención de grado
                            </h2>
                            <hr>
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed text-white " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <i class="material-icons me-3">
                                        school
                                    </i>
                                    Requisitos
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p class="text-justify">
                                        Los estudiantes que deseen obtener el grado de Maestro en
                                                            Sistemas Computacionales, deberán:
                                    </p>
                                    <hr/>
                                    <ul>
                                        <li>
                                            <p class="text-justify">
                                                Presentar y desarrollar un proyecto de estudio
                                                                        individual de carácter técnico, empresarial o docente
                                                                        para su tesis.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Contar con la autorización de impresión de la tesis
                                                                        emitida por el Jefe de la División de Estudios de
                                                                        Posgrado e Investigación, a propuesta del jurado del
                                                                        examen.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Demostrar el dominio de un segundo idioma, el cual
                                                                        deberá acreditar mediante examen TOEFL, con un puntaje
                                                                        de 450 puntos.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Acreditar todas las asignaturas y actividades académicas
                                                                        del programa de Maestría en Sistemas Computacionales, y
                                                                        presentar y aprobar el examen de grado correspondiente.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Presentar evidencia de: desarrollo tecnológico, registro
                                                                        de propiedad intelectual, desarrollo de software o
                                                                        prototipos, patentes y participación en eventos
                                                                        académicos.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Contar con al menos dos artículos publicados en
                                                                        congresos nacionales o internacionales y/o revistas
                                                                        indexadas arbitradas.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Realización de estancia en el sector público, privado o
                                                                        social.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--CONTENEDIR DE REQUISITOS PARA EL EXAMEN DE GRADO-->
                <div class="container-requisitos-examen-grado pservices text-justify">
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item mt-5">
                            <h2 class="text-title mb-3 mt-3">
                                Examen de grado
                            </h2>
                            <hr>
                            <h2 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <i class="material-icons me-3">
                                        edit
                                    </i>
                                    Requisitos
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <p class="text-justify">
                                        Para sustentar el Examen de grado, el estudiante deberá
                                                            cubrir los siguientes requisitos:
                                    </p>
                                    <hr/>
                                    <!--LISTA DE REQUISITOS PARA EXAMEN DE GRADO-->
                                    <ul>
                                        <li>
                                            <p class="text-justify">
                                                Estar dentro del término especificado en el Plan de
                                                                        Estudios Correspondiente.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Solicitar por escrito al Jefe de la División de Estudios
                                                                        de Posgrado e Investigación, fecha, hora y lugar para
                                                                        realizar el Examen de grado, anexando los siguientes
                                                                        documentos:
                                            </p>
                                            <!--INICIO SUBLISTA REQUSITOS DOCUEMNTOS EXAMEN DE GRADO-->
                                            <div class="requisitos-examen-grado">
                                                <ul>
                                                    <li>
                                                        <p class="text-justify">
                                                            Constancia de aprobación de la totalidad de las
                                                                                          materias correspondientes al programa de Maestría
                                                                                          en Sistemas Computacionales, emitida por el
                                                                                          Departamento de Servicios Escolares, con el
                                                                                          promedio general mínimo requerido (80), sin
                                                                                          considerar los créditos de la tesis.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Constancia de finalización de estancia emitida por
                                                                                          la institución del lugar de realización de la
                                                                                          estancia.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Constancia del manejo del idioma inglés, con un
                                                                                          nivel o puntaje mínimo de 450 puntos del examen
                                                                                          TOEFL.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Autorización de impresión de la tesis emitida por
                                                                                          la División de Estudios de Posgrado e
                                                                                          Investigación.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Carta de autorización de impresión de la tesis
                                                                                          emitida por los miembros del Comité Tutorial y los
                                                                                          revisores.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Los ejemplares impresos o electrónicos necesarios
                                                                                          de la tesis aprobada por el Jurado de Examen,
                                                                                          según el número de miembros del jurado.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Un ejemplar impreso para la biblioteca y copia del
                                                                                          archivo electrónico en formato PDF en CD.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Constancia expedida por el Departamento de
                                                                                          Servicios Escolares para validar que se está́ en
                                                                                          término para la realización del Examen de grado.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Documento que avale la cobertura de los derechos
                                                                                          de examen y de expedición de los documentos
                                                                                          correspondientes.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Documento de no adeudos económicos, ni de
                                                                                          material, ni de equipo con las oficinas,
                                                                                          laboratorios, talleres y biblioteca del plantel.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p class="text-justify">
                                                            Autorización correspondiente, en caso de haber
                                                                                          solicitado ampliación de periodo para examen.
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <!--FIN SUBLISTA REQUSITOS DOCUEMNTOS EXAMEN DE GRADO-->
                                        </li>
                                    </ul>
                                    <!--FIN LISTA DE REQUISITOS PARA EXAMEN DE GRADO-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <!--FIN DE LA COLUMNA 1-->
        </div>
        <!--FIN DEL RENGLON-->

        <div
            class="row">
            <!--INICIO DE LA COLUMNA 2-->
            <div class="col col2">
                <figure class="figure">
                    <img src="../../../assets/images/interfaz/pexels-ekrulila-2292837.jpg" class="figure-img img-fluid rounded" alt="...">
                </figure>
            </div>
            <!--FIN DE LA COLUMNA 2-->
        </div>
    </div>
    <!--FIN DEL CONTENEDOR DE COLUMNAS-->
</div>
<!--FIN DEL CONTENEDOR PRINCIPAL-->
