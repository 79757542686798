<div class="container mt-5">
<div class="row">
  <div class="column">
    <div class="card" style="width: 18rem;">
      <div class="card-body">
        <h5 class="card-title">Telefonos</h5>
        <p class="card-text">
          Jefa de la División de Estudios de Posgrado e Investigación<br>
          Mtra. María del Rosario Bernal Díaz<br>
          Tel. 492 9245366 Ext 329<br><br>
          Coordinador:<br>
          Dr. Jaime Iván López Veyna<br>
          Tel. 492 9245366 Ext 329<br>
        </p>
      </div>
    </div>
  </div>
  <div class="column">
    <div class="card" style="width: 18rem;">
      <div class="card-body">
        <h5 class="card-title">Redes Sociales</h5>
        <p class="card-text">
          <i class="large material-icons me-3">
            facebook
          </i><a class="link" href="https://www.facebook.com/estudianteITZ">Facebook</a><br>
          <i class="large material-icons me-3">
            mark_as_unread
          </i><a>dir_zacatecas@tecnm.mx </a>
        </p>
      </div>
    </div>
  </div>
  <div class="column">
    <div class="card" style="width: 18rem;" >
      <div class="card-body">
        <h5 class="card-title">Dirección</h5>
        <p class="card-text">
          Carr. Panamericana Entronque a Guadalajara S/N, Col.<br>
          La Escondida, Zacatecas, Zacatecas apartado Postal. 98000<br>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.7201559079917!2d-102.62919368503675!3d22.775764385078578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868251fc1b22e585%3A0xc0bda5c7dcae6f12!2sInstituto%20Tecnol%C3%B3gico%20de%20Zacatecas!5e0!3m2!1ses-419!2smx!4v1622401133707!5m2!1ses-419!2smx" width="250" height="150" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </p>
      </div>
    </div>
  </div>
</div>
