<div class="main-container">
  <!--CONTENEDOR DE COLUMNAS-->
  <div class="container">
   
   
      <!--RENGLON 1-->
      <div class="container-descargas">
        <!--ACCORDION 1-->
        <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
          <div class="accordion-item">
              <h2 class="text-title mb-3">
                Descargas
              </h2>
              <hr>
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Documentos
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <!--ACCORDION BODY  1-->
              <div class="accordion-body">

                <div class="row">
                  <div class="enlace mt-3">
                    <div class="row">
                      <div class="col d-flex justify-content-center align-middle">
                        <i>
                          <img
                            src="../../../assets/images/pdf.svg"
                            alt=""
                            class="icon-document me-3"
                          />
                        </i>


                      </div>

                      <div class="col">
                        <h5 class="text-tit d-flex align-middle">
                          Guia de estudio
                        </h5>
                      </div>

                      <div class="col d-flex justify-content-center align-middle"
                      >
                        <a
                          class="btn btn-lg btn-success d-flex align-middle"
                          href="../../../assets/documents/ficha_ingreso.docx"
                          download="Ficha de ingreso.docx"
                          >Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="enlace mt-3">
                    <div class="row">
                      <div class="col d-flex justify-content-center align-middle"
                      >
                        <i>
                          <img
                            src="../../../assets/images/doc.svg"
                            alt=""
                            class="icon-document me-3"
                          />
                        </i>
                      </div>

                      <div class="col"> 
                        <h5 class="
                        text-title
                        d-flex
                        align-middle
                        justify-content-center
                      "
                    >
                      Ficha de ingreso
                    </h5>
                      </div>

                      <div class="col d-flex justify-content-center">
                        <a
                          class="btn btn-lg btn-success d-flex"
                          href="../../../assets/documents/ficha_ingreso.docx"
                          download="Ficha de ingreso.docx"
                          >Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="enlace mt-3">
                    <div class="row">
                      <div class="col d-flex justify-content-center align-middle">
                        <i>
                          <img
                            src="../../../assets/images/triptico.png"
                            alt=""
                            class="icon-document me-2"
                          />
                        </i>


                      </div>

                      <div class="col">
                        <h5
                        class="
                          text-title
                          d-flex
                          align-middle
                          justify-content-center
                          me-3
                        "
                      >
                        Triptico
                      </h5>
                      </div>

                      <div class="col d-flex justify-content-center">
                        <a
                          class="btn btn-lg btn-success d-flex"
                          href="../../../assets/documents/triptico.png"
                          download="triptico.png"
                          >Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="enlace mt-3">
                    <div class="row">
                      <div class="col d-flex justify-content-center align-middle">
                        <i>
                          <img
                            src="../../../assets/images/pdf.svg"
                            alt=""
                            class="icon-document me-2"
                          />
                        </i>


                      </div>

                      <div class="col">
                        <h5
                        class="
                          text-title
                          d-flex
                          align-middle
                          justify-content-center
                          me-3
                        "
                      >
                        Convocatoria 2020
                      </h5>
                      </div>

                      <div class="col d-flex justify-content-center">
                        <a
                          class="btn btn-lg btn-success d-flex"
                          href="../../../assets/documents/7.1-6. Convocatoria_2020.pdf"
                          download="7.1-6. Convocatoria_2020.pdf"
                          >Descargar
                        </a>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
   
  </div>
</div>
