<div class="container d-flex justify-content-center ">
<!-- Team -->
<section id="team" class="pb-5 ">
  <div class="container" >
    <div class="row">
      <h5 class="section-title h1">Nucleo Academico</h5>
    </div>
    <div class="row">
      <div class="col">
        <!-- Team member JOSE MARTIN BARAJAS-->
        <div class="col-lg-10 col-md-10 col-md-10">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/drive-download-20210531T001903Z-001/Martin.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Jose Martin Barajas</h4>
                    <p class="card-text pservices text-center ">
                      Maestría <br />
                      Tiempo Completo <br />
                      Ingeniería de Software
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-break mt-4">
                    <h4 class="card-title mb-3 pservices text-center">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      José Martín Barajas Guerrero nacio en Zacatecas, Zac. 13
                      de Julio de 1971. Ingeniero en Sistemas Computacionales
                      por el Instituto Tecnológico de Zacatecas, Zacatecas, Zac.
                      México, 1992. Especialidad en computación por la
                      Universidad Autónoma de Zacatecas, Zacatecas, Zac. México,
                      1993. Maestro en Ciencias de Ingeniería Administrativa por
                      el Instituto Tecnológico de Celaya, Celaya, Gto. México,
                      2008. Jefe del Departamento de servicios Escolares en el
                      Instituto Tecnológico de Zacatecas. Analista y Programador
                      de varios Sistemas de Información en la Secretaria de
                      Educación del Estado de Zacatecas, Zacatecas, México.
                      Líder de proyecto en el desarrollo del Sistema Integral de
                      Información Administrativa y Financiera (SIIAF) dentro de
                      la Universidad Autónoma de Zacatecas, Zacatecas, Zac.,
                      Mexico. Docente y jefe de laboratorio de cómputo, en el
                      Instituto Tecnológico de Zacatecas, Zacatecas, México.
                      Áreas de Investigación Ingeniería de Software, Desarrollo
                      de Software, Cómputo en la nube.
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>

      <div class="col">
        <!-- Team member JAIME IVAN LOPEZ VEYNA-->
        <div class="col-md-10 col-sm-0 col-sm-0 pservices text-justify ">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/drive-download-20210531T001903Z-001/ivan.jpg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Jaime Ivan Lopez Veyna</h4>
                    <p class="card-text">
                      Doctorado <br />
                      Tiempo Completo <br />
                      Ingeniería de Software
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-center mt-4">
                    <h4 class="card-title mb-3">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      Obtuvo una licenciatura en Sistemas Computacionales en el
                      Instituto Tecnológico de Zacatecas (México) en 1997. En
                      2001 recibió el grado de Maestro en Ciencias
                      Computacionales por el Instituto Tecnológico de Toluca
                      (México) y el Doctorado en Ciencias de la Computación por
                      el Laboratorio de Tecnologías de la Información del Centro
                      de Investigación y Estudios Avanzados del Instituto
                      Politécnico Nacional (México) en 2014. De 2015 a 2017
                      realizó una estancia postdoctoral como investigador en
                      Barcelona, en el Centro de Investigación Barcelona
                      Supercomputing Center - Centro Nacional de Súper cómputo
                      en el Departamento de Ciencias de la Computación en el
                      grupo de almacenamiento de datos con el proyecto Dataclay.
                      Sus lineas de investigación incluyen, Machine Learning,
                      Big, Data, Chatbots, y Procesamiento de Datos a gran
                      escala.
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!-- Team member -->
        <div class="col-md-10 col-sm-6 col-sm-4">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/GNF2.jpg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Jose Gabriel Navarro</h4>
                    <p class="card-text">                
                        Maestría <br>
                        Tiempo Parcial <br>
                        Ingeniería de Software
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-center mt-4">
                    <h4 class="card-title mb-3">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      José Gabriel Navarro Favela nació en Zacatecas, Zac., el 24 de diciembre de 1970.
                      Licenciado en Sistema de Computación Administrativa por el Instituto Tecnológico de Estudios Superiores de Monterrey (ITESM), 1993. <br>
                      Maestro en Informática Administrativa por la Universidad Autónoma de Durango (UAD), 2005. <br>
                      Maestro en Sistemas Computacionales por el Instituto Tecnológico de Orizaba (ITO), 2014. <br>
                      <br>
                      Docente de Licenciatura en el Instituto Tecnológico de Zacatecas (ITZ). <br>
                      Analista, diseñador y líder de proyecto en MPC de México SA de CV. <br>
                      Docente en la Facultad de Contaduría (FCA) de la UAZ. <br>
                      <br>
                      Analista de Productos en Banorte SA. <br>
                      Gerente de Sistemas en Cervezas Moctezuma en Zacatecas SA de CV. <br>
                      Auxiliar de Recursos Financieros en Inmobiliaria Navarro Palacios SA de CV. <br>
                      Auxiliar Administrativo en el Instituto Zacatecano de la Vivienda Social <br>
                      
                      Certificación “Sun Certified Programmer for Java 2 Platform”, 2009. <br>
                      Diplomado “Aplicaciones y herramientas de desarrollos dentro de la tecnología Java”, 2009, Centro Develop en el Instituto Tecnológico de Zacatecas. <br>
                      Diplomado “Programación en Java Stándar y Enterprise”, 2007, Centro de Investigación en Matemáticas (CIMAT). <br>
                      
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>

      <div class="col">
        <!-- Team member -->
        <div class="col-md-10 col-sm-6 col-sm-4">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/drive-download-20210531T001903Z-001/Mariana.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Mariana Ortíz García </h4>
                    <p class="card-text">
                        Maestría <br>
                        Tiempo Completo <br>
                        Ingeniería de Software
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-center mt-4">
                    <h4 class="card-title pservices text-center">Curriculum</h4>
                    <p class="card-text pservices text-justify mt-3">
                      Licenciada en Informática por el Instituto Tecnológico de Durango, 1993. Maestra en SIstemas Computacionales por el Instituto Tecnológico de Orizaba, 2014
                      Coordinadora de la carrera de Licenciatura en el Instituto Tecnológico de Zacatecas.
                      Desarrolladora de sistemas de Información en la empresa TRACCSA., S.A. en la ciudad de Salamanca, Gto.
                      Encargada general de la empresa Ingeniería Organizacional en Durango, Dgo.
                      Capturista de sistemas y costos de obra civil en la Constructora TAO en Durango, Dgo. Docente de Licenciatura y posgrado en el Instituto Tecnológico de Zacatecas
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!-- Team member ELSA ORTEGA AVILA-->
        <div class="col-md-10 col-sm-6 col-sm-4">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/drive-download-20210531T001903Z-001/Elsa.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Elsa Ortega Avila</h4>
                    <p class="card-text">
                      Doctorado <br />
                      Tiempo Completo <br />
                      Ingeniería de Software
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-center mt-4">
                    <h4 class="card-title mb-3">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      Elsa Ortega de Ávila nació en Villanueva, Zac el 18 de
                      octubre de 1973. Ingeniero en Sistemas Computacionales por
                      el Instituto Tecnológico de Zacatecas, México en enero de
                      1996. Maestra en Ingeniería con orientación en Computación
                      por la Universidad Autónoma de Zacatecas en 2007. Doctora
                      en Ciencias de la educación por la Universidad autónoma de
                      Coahuila en el año de 2014. Es docente del Instituto
                      Tecnológico de Zacatecas en las Licenciaturas en
                      Ingeniería en Sistemas e Informática y en Maestría de
                      Ingeniería en Sistemas, con varias materias enfocadas a
                      las matemáticas y a la Metodología de la Investigación.
                      Docente de la Unid en varias licenciaturas con la materia
                      de Metodología de la Investigación. Docente de la
                      Secundaria Técnica no. 70. Como maestra de Computación.
                      Áreas de investigación: Educación, deserción escolar,
                      desarrollo de objetos de Aprendizaje (ODA) e ingeniería de
                      software
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>

      <div class="col">
        <!-- Team member ELSA ORTEGA AVILA-->
        <div class="col-md-10 col-sm-6 col-sm-4">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/andres.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Andres Salas Núñez</h4>
                    <p class="card-text">

                      Maestría <br>
                      Tiempo Completo <br>
                      Ingeniería de Software
                      
                      
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-center mt-4">
                    <h4 class="card-title mb-3">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      ANDRÉS SALAS NÚÑEZ, fecha de nacimiento 30/jun/71 Juchipila Zacatecas, nacionalidad mexicana, email: andres.salas@zacatecas.tecnm.mx, CVU: 834416, puesto actual  catedrático del Instituto Tecnológico de Zacatecas/Tecnológico Nacional de México en las áreas de Licenciatura de ISC e IINF y Posgrado Maestría en Sistemas Computacionales.
                      Grados académicos, Licenciatura en Ingeniería en Sistemas Computacionales del Instituto Tecnológico de Zacatecas  1989-1993., Especialidad en Computación en la Universidad Autónoma de Zacatecas 1994, Maestría en Sistemas Computacionales en el Instituto Tecnológico de Orizaba 2014.
                      Jefe del Departamento de Sistemas y Computación del Instituto Tecnológico de Zacatecas del 1995-1999,  docente- catedrático del Instituto Tecnológico de Zacatecas desde 1995 a la fecha.
                      Formación en diplomados de “Formación y Desarrollo de Competencias Docentes”, “Formación de Tutores” y Certificación como Auditor Líder en los Sistemas de Gestión de la Calidad”.
                      Generación de documentos o artículos “TSPi to Manage Software Projects in Outsourcing Enviroments”, entre otros.
                      Asesor-líder de proyectos de residencias y estancias profesionales a nivel licenciatura y director-asesor de proyectos de tesis a nivel maestría.
                      Áreas de desarrollo e investigación Ingeniería de Software, Análisis de Datos, Metodologías para la gestión de proyectos.                       
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>
    </div>

    <div class="row">
      <div class="col">

        <!-- Team member LUIS TORRES-->
        <div class="col-lg-10 col-md-10 col-md-10">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/Luis.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Luis Eduardo Torres Hernández</h4>
                    <p class="card-text">

                      Maestría <br>
                      Tiempo Completo <br>
                      Ingeniería de Software
                      
                      
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-break mt-4">
                    <h4 class="card-title  pservices text-center mb-3">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      Luis Eduardo Torres Hernández nació en Fresnillo, Zacatecas. 23 de Octubre de 1971.
                      Ingeniero en Sistemas Computacionales por el Instituto Tecnológico de Zacatecas, Zacatecas, Zac. México, 1993. 
                      Maestro en Sistemas Computacionales por el Instituto Tecnológico de Orizaba, México, 2014.
                      Jefe del Centro de Computo del Instituto Tecnológico de Zacatecas.
                      Jefe de Laboratorio de Computo del Instituto Tecnológico de Zacatecas. 
                      Docente del departamento de Sistemas y Computación, en el Instituto Tecnológico de Zacatecas.
                      Áreas de Investigación Ingeniería de Software, Administración de Redes, Cómputo en la nube.
                      
                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>

      <div class="col">
        <!-- Team member JAIME IVAN LOPEZ VEYNA-->
        <div class="col-md-10 col-sm-0 col-sm-0">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/alfredo.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Alfredo Garcia Castañon</h4>
                    <p class="card-text">


                      Maestría <br>
                      Tiempo Completo <br>
                      Ingeniería de Software
                      
                      
                      
                      
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-center mt-4">
                    <h4 class="card-title">Curriculum</h4>
                    <p class="card-text pservices text-justify">
                      Alfredo García Castañón nació en Zacatecas Zac., el 09 de enero de 1976. 
                      Ingeniero en Sistemas Computacionales por el Instituto Tecnológico de Zacatecas, Zacatecas Zac.  México 1998. Maestro en Informática Administrativa por la Universidad Autónoma de Durango  2017. 
                      Jefe del Departamento de Servicios Escolares (2003-2015). 
                      Administrador del sistema de almacén de farmacias y tiendas ISSSTEZAC. 
                      Soporte de sistemas AS/400 grupo Scanda Monterrey NL 
                      Áreas de investigación Ingeniería de Software, investigación operativa.

                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!-- Team member JOSE MARTIN BARAJAS-->
        <div class="col-lg-10 col-md-10 col-md-10 d-flex justify-content-center">
          <div
            class="image-flip"
            ontouchstart="this.classList.toggle('hover');"
          >
            <div class="mainflip">
              <div class="frontside">
                <div class="card">
                  <div class="card-body text-center">
                    <p>
                      <img
                        class="img-fluid"
                        src="../../../assets/images/docentes/esaul.jpeg"
                        alt="card image"
                      />
                    </p>
                    <h4 class="card-title">Edgar Esaúl Saucedo Becerra</h4>
                    <p class="card-text">
                      Maestría <br>
                      Tiempo Parcial <br>
                      Ingeniería de Software <br>
                    </p>
                    <a href="#" class="btn btn-primary btn-sm"
                      ><i class="fa fa-plus"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="backside">
                <div class="card">
                  <div class="card-body text-break mt-4">
                    <h4 class="card-title mb-3 pservices text-center">Curriculum </h4>
                    <p class="card-text pservices text-justify">
                      Edgar Esaúl Saucedo Becerra nació en  Zacatecas, Zac. el 06 de Marzo de 1974.

                      Ingeniero en Sistemas Computacionales por el Instituto Tecnológico de Zacatecas, 1998. Maestra en Tecnologías de la Información por la Universidad Interamericana para el Desarrollo, 2010.
                      Doctorado en Tecnología Educativa por el Centro Escolar Mar de Cortés (Créditos completos) <br>

                      Docente en el Instituto Tecnológico de Zacatecas.
                      Académico profesional Administrado del Centro de Cómputo Unidad Académica de Matemáticas en la Universidad Autónoma de Zacatecas.
                      Perito en Dactiloscopia Servicios Periciales en el Gobierno del Estado de Zacatecas.
                      Analista en el Fideicomiso para la Liquidación al Subsidio de la Tortilla, LICONSA Gobierno Federal

                    </p>
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-skype"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          class="social-icon text-xs-center"
                          target="_blank"
                          href="#"
                        >
                          <i class="fa fa-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ./Team member -->
      </div>
    </div>

  </div>
</section>
<!-- Team -->
</div>

