<section id="team" class="pb-5">
  <div class="container">
    <h5 class="section-title h1 text-center">Segunda Generación<h3>2019-2021</h3></h5>
    <div class="row">

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Barajas Hernandez Enrique</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    Andrés Salas Nuñez<br /></a>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Hernádez Mier Juan Francisco</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    Elsa Ortega de Ávila<br /><br></a>
                    Tema de Tesis: Sistema Web para el control y seguimiento de actividades extraescolares en el Tecnm basado en la norma ISO 9001:2015<br><br>
                    Director de tesis: Elsa Ortega de Ávila<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Nuñez Adame José de Jesús</h4>
                  <p class="card-text">
                    Tutor: <br /><a href="nucleo-academico">
                    Luis Eduardo Torres Hernández<br /></a>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Rodríguez Castillo Luis Alberto</h4>
                  <p class="card-text">
                    Tutor:<a href="nucleo-academico">
                    Luis Eduardo Torres Hernández<br /><br></a>
                    Tema de Tesis: Sistema Web para el control y seguimiento de actividades extraescolares en el Tecnm basado en la norma ISO 9001:2015<br><br>
                    Director de tesis: Luis Eduardo Torres Hernández<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Segovia Báez Francisco Javier</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    José Martín Barajas Guerrero<br /><br></a>
                    Tema de Tesis: Desarrollo de una aplicación hibrida para Bussiness Intelligences basada en metodologías agiles<br><br>
                    Director de tesis: José Martín Barajas Guerrero<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
