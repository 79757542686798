<div class="main-container d-flex justify-contanet-center">
    <div class="row">
        <div class="accordion accordion-flush " id="accordionFlushExample">
            <div class="accordion-item ">
                <h2 class="text-title mb-4">
                    Líneas generación y/o aplicación del conocimiento del programa 
                </h2>
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed text-white " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  LGAC
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body pservice text-justify">
                    <h2 class="text-title mt-3">
                        Ingeniería de Software
                    </h2>
                    <hr>
                    <p>
                    La MSC ha definido su línea de generación y aplicación de conocimiento (LGAC) en función de los perfiles y las diferentes áreas de investigación de cada uno de los miembros del Nucle Académico. Tras analizar las compatibilidades entre las áreas de nuestros investigadores, se ha definido la Ingeniería de Software como LGAC.
                    Esta línea de trabajo tiene como objetivo principal brindar los conocimientos necesarios sobre metodologías y técnicas  de análisis, diseño, implementación y prueba para la gestión y generación de sistemas  Web y móviles,  con estándares de calidad.
                    </p>

                </div>
              </div>
            </div>
        </div>
    </div>

</div>