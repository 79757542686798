import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requisitos-ingreso',
  templateUrl: './requisitos-ingreso.component.html',
  styleUrls: ['./requisitos-ingreso.component.css']
})
export class RequisitosIngresoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
