<!--Inicio contenedor principal-->

<div class="main-container">
  <div class="container mt-3">
    <!--I R1-->
    <div class="row">
      <!--I C1-->
      <div class="col">
        <!--I CAI-->
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <!--I ACI-->
          <div class="accordion-item">
            <h2 class="text-title mb-3">Proceso de selección de aspirantes</h2>
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <i class="large material-icons me-3 text-white"> done </i>
                Requisitos
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <!--I ACB-->
              <div class="accordion-body">
                <!--I C-RSA-->
                <div class="container-requisitos-seleccion-aspirantes">
                  <p class="text-justify">
                    El programa de Maestría en Sistemas Computacionales se
                    ofertara semestralmente, el procedimiento de selección de
                    aspirantes involucra aspectos académicos y del perfil del
                    estudiante. El procedimiento que deberá seguir un aspirante
                    consistirá en:
                  </p>
                  <hr />
                  <!--Lista de requisitos-->
                  <ul>
                    <li>
                      <p class="text-justify">
                        Cumplir con los siguientes requisitos:
                      </p>
                      <!-- I SUBLITSA DE REQUISITOS-->
                      <ul>
                        <li>
                          <p class="text-justify">
                            Poseer un título de licenciatura de carrera afín al
                            programa de Maestría.
                          </p>
                        </li>
                        <li>
                          <p class="text-justify">
                            Contar con un promedio mínimo de 80 o equivalente.
                          </p>
                        </li>
                        <li>
                          <p class="text-justify">
                            Llenar la solicitud de ingreso anexándole el
                            curriculum vitae.
                          </p>
                        </li>
                        <li>
                          <p class="text-justify">
                            Acreditar el examen de admisión o cursar y aprobar
                            las asignaturas propedéuticas con una calificación
                            mínima de 80.
                          </p>
                        </li>
                        <li>
                          <p class="text-justify">
                            Obtener el puntaje mínimo de 1000 en el examen de
                            habilidades (CENEVAL EXANI III).
                          </p>
                        </li>
                        <li>
                          <p class="text-justify">
                            Obtener el puntaje mínimo de 400 en el examen TOEFL
                            de inglés.
                          </p>
                        </li>
                      </ul>
                      <!-- F SUBLITSA DE REQUISITOS-->
                    </li>
                    <li>
                      <p class="text-justify">
                        El candidato comparecerá ante el Comité de Selección del
                        Consejo de Posgrado de la Maestría en Sistemas
                        Computacionales para la entrevista de selección.
                      </p>
                    </li>
                    <li>
                      <p class="text-justify">
                        En función de los resultados el Consejo de Posgrado
                        emite el dictamen correspondiente para el candidato.
                      </p>
                    </li>
                    <li>
                      <p class="text-justify">
                        Entrega de carta de aceptación o rechazo al candidato.
                      </p>
                    </li>
                  </ul>
                  <!-- F Lista de requisitos-->
                </div>
                <!--F C-RSA-->
              </div>
              <!--F ACB-->
            </div>
          </div>

          <!--F ACI-->
        </div>
        <!--F CAI-->
      </div>
      <!--F C1-->

      <!--I C2-->
      <div class="col d-flex alig-middle justify-content-center">
        <div class="img-container">
          <img
            src="../../../assets/images/students.jpg"
            class="rounded"
            alt="..."
          />
        </div>
      </div>
      <!--F C2-->
    </div>
    <!--F R1-->
  </div>
</div>

<!--Fin contenedor principal-->
