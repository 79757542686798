<div class="container mt-5">
  <!--ACORDION DE OBJETIVOS GENERALES-->
  <div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item mt-5 mb-5">
      <h2 class="accordion-header" id="flush-headingOne">
        <h2 class="text-title mb-3">Objetivos Generales</h2>
        <button
          class="accordion-button collapsed text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseOne"
          aria-expanded="false"
          aria-controls="flush-collapseOne"
        >
          <i class="large material-icons me-3"> done_all </i>
          Generales
        </button>
      </h2>
      <div
        id="flush-collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body">
          <p class="text-break">
            Formar profesionales a nivel posgrado orientados al desarrollo de
            actividades académicas y profesionales en el área de Sistemas
            Computacionales que integren habilidades para el desarrollo y
            aplicación de conocimiento técnico y científico utilizando
            tecnologías computacionales que den respuesta a las necesidades de
            las empresas locales, regionales y nacionales con apego a la ética
            profesional.
          </p>
        </div>
      </div>
    </div>

    <!-- ACORDION OBJETVIOS ESPECIFICOS-->
    <div class="accordion-item mt-5">
      <h2 class="accordion-header" id="flush-headingTwo">
        <h2 class="text-title mb-3">Objetivos Especificos</h2>
        <button
          class="accordion-button collapsed text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseTwo"
          aria-expanded="false"
          aria-controls="flush-collapseTwo"
        >
          <i class="large material-icons me-3"> done </i>
          Especificos
        </button>
      </h2>
      <div
        id="flush-collapseTwo"
        class="accordion-collapse collapse"
        aria-labelledby="flush-headingTwo"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body">
          <ul>
            <li>
              <p class="text-break">
                Generar proyectos de investigación con un enfoque
                multidisciplinario, orientados a fortalecer las capacidades
                científicas y tecnológicas y de innovación en la solución de
                problemas en el entorno local, nacional e internacional.
              </p>
            </li>
            <li>
              <p class="text-break">
                Formar profesionistas capaces de aplicar conocimientos
                científicos y tecnológicos en el entorno laboral.
              </p>
            </li>
            <li>
              <p class="text-break">
                Fortalecer el desarrollo de actividades académicas y
                profesionales en el área de sistemas computacionales.
              </p>
            </li>
            <li>
              <p class="text-break">
                Participar en convocatorias emitidas por parte de CONACyT,
                PRODEP, PROSOFT y Gobiernos Federales, Estatales y Municipales
                que involucren al Instituto Tecnologico de Zacatecas en conjunto
                con empresas.
              </p>
            </li>
            <li>
              <p class="text-break">
                Fomentar la transferencia de tecnología entre universidades e
                instituciones gubernamentales
              </p>
            </li>
            <li>
              <p class="text-break">
                Actualizar profesionales en el área de sistemas computacionales
                a fin de aplicar sus conocimientos en el ámbito laboral con
                ética profesional.
              </p>
            </li>
            <li>
              <p class="text-break">
                Encauzar el desempeño profesional hacia una mayor comprensión de
                las necesidades del sector productivo, y alentar su
                participación en el mismo.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--ACORDION METAS DEL PLAN DE ESTUDIOS-->
    <div class="accordion-item mt-5">
      <h2 class="accordion-header" id="flush-headingThree">
        <h2 class="text-title mb-3">Metas</h2>
        <button
          class="accordion-button collapsed text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseThree"
          aria-expanded="false"
          aria-controls="flush-collapseThree"
        >
          <i class="large material-icons me-3"> turned_in </i>
          Metas
        </button>
      </h2>
      <div
        id="flush-collapseThree"
        class="accordion-collapse collapse"
        aria-labelledby="flush-headingThree"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body">
          <p class="text-justify">
            Posicionar la Maestría en Sistemas Computacionales en el 
            estado de Zacatecas como la principal opción para estudiar 
            y actualizarse en el área de sistemas computacionales, 
            contribuyendo a las demandas y requerimientos locales, 
            regionales, nacionales e internacionales, de manera que:
          </p>
          <div class="contianer-lista-metas">
            <ul>
              <li>
                <p class="text-justify">
                  Se favorezca la formación de recursos humanos calificados.
                </p>
              </li>
              <li>
                <p class="text-justify">
                  Se impulse la innovación y el desarrollo tecnológico.
                </p>
              </li>
              <li>
                <p class="text-justify">
                  Se promueva y fortalezca la vinculación con asociaciones, empresas, organismos e instituciones públicas y privadas de la sociedad zacatecana.
                </p>
              </li>
              <li>
                <p class="text-justify">
                  Se participe activamente en la solución de problemas para contribuir al desarrollo socioeconómico del país.
                </p>
              </li>
              <li>
                <p class="text-justify">
                  Se facilite la inserción de los egresados en empresas, organismos e instituciones públicas y privadas de la región.
                </p>
              </li>
              <li>
                <p class="text-justify">
                  Se impulse la participación en convocatorias para la obtención de recursos que permita fortalecer la infraestructura del programa.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
