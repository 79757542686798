<!--Seccion para el perfil de Ingreso-->
<div class="container mt-5 pservices text-justify">
  
  <div class="container-xl mt-5">
    <div class="container mt-5">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <!--  PERFIL DE INGRESO -->
        <div class="accordion-item mb-5">
          <h2 class="text-title mb-3">Perfil de ingreso</h2>
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              class="accordion-button collapsed text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
            <i class="large material-icons me-3" >
              done
            </i>
              Ingreso
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <!-- ASPECTOS QUE CONLLEVA EL PERFIL DE INGRESO -->
          <div class="accordion-body">
              <p class="text-break">
                Los estudiantes interesados en ingresar a la Maestría en
                Sistemas Computacionales, deberán contar con una licenciatura en
                las siguientes áreas del conocimiento: Sistemas Computacionales
                , Informática, Ingeniería en Tecnologías de la Información y las
                Comunicaciones, o áreas afines. Deberá conocer las al menos un
                lenguaje de programación, así como las herramientas básicas de
                sistemas computacionales, habilidades matemáticas e interés por
                la lectura. Además, el aspirante deberá trabajar en equipos
                multidisciplinarios, demostrando eficiencia en su desempeño
                personal de manera metodológica y disciplinada con adecuada
                expresión oral y escrita, mostrando interés por contribuir al
                bienestar, la sustentabilidad y desarrollo económico, cultural y
                científico de la región.
              </p>
              <!--LISTA DE LIS ASPECTOS QUE CONLLEVA EL PERFIL DE INGRESO-->
              <ul>
                <hr>
                <h3>Conocimientos básicos</h3>
                <li>
                  <p class="text-break"></p>
                  <p class="text-break">Matemáticas</p>
                </li>
                <li>
                  <p class="text-break"></p>
                  <p class="text-break">Programación</p>
                </li>
                <li>
                  <p class="text-break"></p>
                  <p class="text-break">Metodología de la investigación</p>
                </li>
                <li>
                  <p class="text-break"></p>
                  <p class="text-break">
                    Dominio de la lectura y comprension de ingles técnico
                  </p>
                </li>
                <hr>
                <!-- FIN DE CONOCIMEINTOS BASICOS -->

                <h3>Habilidades</h3>

                <li>
                  <p class="text-break">
                    Pensamiento lógico, analítico y crítico.
                  </p>
                </li>
                <li>
                  <p class="text-break">Comunicación oral y escrita.</p>
                </li>
                <li>
                  <p class="text-break">Capacidad de integración.</p>
                </li>
                <li>
                  <p class="text-break">Trabajo multidisciplinario</p>
                </li>
                <li>
                  <p class="text-break">Espiritu emprendedor</p>
                </li>
                <li>
                  <p class="text-break">
                    Apertura para la investigación aplicada y el desarrollo de
                    tecnología.
                  </p>
                </li>
                <hr>
                <!-- FIN DE CONOCIMEINTOS HABILIDADES -->

                <h3>Actitudes y valores</h3>
                <li>
                  <p class="text-break">Responsabilidad.</p>
                </li>
                <li>
                  <p class="text-break">Ética</p>
                </li>
                <li>
                  <p class="text-break">Espiritu de superación</p>
                </li>
                <li>
                  <p class="text-break">Respeto</p>
                </li>
                <li>
                  <p class="text-break">Honestidad</p>
                </li>
                <li>
                  <p class="text-break">Integridad</p>
                </li>
                <li>
                  <p class="text-break">
                    Disponibilidad para realizar estancias en industrias o
                    laboratorios de investigación especializados.
                  </p>
                </li>
              </ul>
            </div>
          </div><!-- FIN DE LOS ASPECTOS QUE CONLLEVA EL PERFIL DE INGRESO -->
        </div><!-- FIN DEL PERFIL DE INGRESO -->

       

        <!--PERFIL DE EGRESO-->
        
        <div class="accordion-item">
          <h2 class="text-title mt-3">Perfil de egreso</h2>
          <h2 class="accordion-header" id="flush-headingTwo">
            <button
              class="accordion-button collapsed text-white"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
            <i class="large material-icons" style="margin-right:1rem;">
              done_all
            </i>
              Egreso
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <!--LISTA DE LIS ASPECTOS QUE CONLLEVA EL PERFIL DE EGRESO-->
            <div class="accordion-body">
              <p class="text-break">
                El egresado de la Maestría en Sistemas Computacionales contará con 
                habilidades y destrezas requeridas para atender, desarrollar e 
                implementar soluciones innovadoras a problemas en el ámbito 
                público y privado, social, e institucional, local, estatal, 
                nacional e internacional, aplicando técnicas computacionales 
                con metodologías de vanguardia.
              </p>
              <ul>
                <hr>
                <h3>
                  Capacidades
                </h3>
                <li>
                  <p class="text-break">
                    Solucionar problemas en la frontera del conocimiento con el uso adecuado de tecnologías de cómputo, métodos y aplicaciones.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Contribuir en la competitividad de empresas mediante la aplicación adecuada y efectiva de las tecnologías de computación.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Proponer alternativas de solución innovadoras dentro del contexto técnico y organizacional de la empresa.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Formar, desarrollar y evaluar grupos de trabajo de alto desempeño.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Proporcionar asesoría técnica a las instituciones para optimizar sus recursos de información.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Actualizar conocimientos en el área de computación.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Evaluar nuevas tecnologías de información con el objetivo de aplicarlas adecuadamente en el sector público y privado.

                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Analizar, diseñar e implementar sistemas, servicios y aplicaciones que utilicen diversas técnicas de la Ingeniería de Software.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Desarrollar capacidades en la toma de decisiones, trabajo en equipo, apertura al cambio con un alto sentido de ética profesional.

                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Ingresar a sociedades científicas y tecnológicas.
                  </p>
                </li>
                <hr>

                 <!-- COMPETENCIAS DEL EGRESADO -->
                <h3>
                  Competencias
                </h3>
                <li>
                  <p class="text-break">
                    Validar la calidad de los productos de software a través del uso de metodologías para el desarrollo y gestión de sistemas computacionales.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Integrar metodologias en el desarrollo e implementación de bases de datos y de desarrollo de software.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Comparar diferentes metodologías de desarrollo de sistemas para la gestión de sistemas computacionales. 
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Identificar oportunidades de Investigación y desarrollo en su ámbito laboral para mejorar las condiciones de competitividad de la misma.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Seleccionar en forma crítica y ética las diferentes alternativas tecnológicas, que les permita mejorar procesos de transferencia tecnológica y de innovación.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Aprender a aprender, asimilar, incorporar, investigar, adaptar, innovar y mantenerse actualizado en el área de Sistemas Computacionales.

                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Gestionar de manera eficiente, rigurosa y ética proyectos de investigación
                  </p>
                </li>
                <hr>
                <!-- CONOCIMEINTOS DEL EGRESADO -->
                <h3>
                  Conocimientos
                </h3>
                <li>
                  <p class="text-break">
                    Tecnologías emergentes.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Técnicas de calidad de software.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Técnicas de arquitectura de software.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Tratamiento de la información (bases de datos, análisis de información para generar valor).

                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Bases sobre lógica de programación.

                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Prácticas empresariales.
                  </p>
                </li>
                <li>
                  <p class="text-break">
                    Inglés (lectura y redacción).
                  </p>
                </li>
                <hr>

                 <!-- HABILIDADES DEL EGRESADO -->
                 <h3>
                   Habilidades
                 </h3>
                 <li>
                   <p class="text-break">
                    Diseño y desarrollo de aplicaciones y técnicas de calidad de software.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Aplicación de estándares y certificaciones en programación, redes y bases de datos.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Integración de sistemas.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Modificación de las aplicaciones de tecnologías de la información.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Uso de tecnologías, aplicaciones y prácticas para la colección, integración, análisis, selección y presentación de la información.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Diseño de estructuras de programación.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Planear, administrar y priorizar trabajo.
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Trabajo en equipo 
                   </p>
                 </li>
                 <hr>

                 <!-- ACTITUDES -->
                 <h3>
                   Actitudes
                 </h3>  
                 <li>
                   <p class="text-break">
                    Liderazgo
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Creatividad
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Innovación
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Iniciativa
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Ética
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Colaboración
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Tolerancia
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Respeto
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Servicio
                   </p>
                 </li>
                 <li>
                   <p class="text-break">
                    Disciplina
                   </p>
                 </li>
                 <hr>
              </ul>
            </div>
            <!--FIN DE LISTA DE ASPECTOS PERFIL DE EGRESO-->
          </div>
        </div>
        <!--FIN DEL ACRODEON DE PERFIL DE EGRESO-->
      </div>
    </div>  <!-- FIN DEL CONTENEDOR DE LOS ACRODEONES -->
  </div>
</div>



