<div class="main-container pservices text-justify">

  <div class="container mt-5">

    <!--INICIO DEL RENGLON-->
    <div class="row">
      <!--INICIO DE LA COLUMNA 1-->
      <div class="col">
        <h2 class="text-title mb-3">Requisitos de ingreso</h2>
        <!--ACORDION DE REQUISITOS -->
        <hr class="mb-3">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                <i class="material-icons me-3 text-white"> spellcheck </i>
                Requisitos
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <div class="informacion">
                  <p class="text-title">Requisitos de ingreso</p>
                  <p class="text-justify">
                    Los aspirantes a ingresar a la Maestría en Sistemas
                    Computacionales serán evaluados por el Consejo Académico de
                    la Maestría en Sistemas computacionales, y deberán cumplir
                    con los siguientes requisitos:
                  </p>
                  <hr />
                  <!--REQUISITOS-->
                  <p class="text-title">Requisitos</p>

                  <ul>
                    <li>
                      <p class="cart-text">
                        Ser titulado de una licenciatura afín al programa.
                      </p>
                    </li>
                    <li>
                      <p class="cart-text">
                        Tener un promedio mínimo de ochenta (80) o su
                        equivalente en el nivel licenciatura.
                      </p>
                    </li>
                    <li>
                      <p class="cart-text">
                        Aprobar el examen de admisión o cursar y aprobar las
                        asignaturas propedéuticas con una calificación mínima de
                        80, las cuales no tienen un valor en créditos.
                      </p>
                    </li>
                    <li>
                      <p class="cart-text">
                        Demostrar habilidades a través del examen EXANI III de
                        Ceneval y obtener un mínimo de 1000 puntos.
                      </p>
                    </li>
                    <li>
                      <p class="cart-text">
                        Presentar currículum vitae con los documentos
                        probatorios de su trayectoria.
                      </p>
                    </li>
                    <li>
                      <p class="card-text text-justify">
                        Entregar dos cartas de recomendación académica,
                        dirigidas al jefe de la División de Estudios de Posgrado
                        e Investigación, avaladas por especialistas en el área.
                      </p>
                    </li>
                    <li>
                      <p class="cart-text">
                        Cumplir con la entrevista ante el Comité de Admisión.
                      </p>
                    </li>
                    <li>
                      <p class="cart-text">
                        Mostrar habilidades para la lectura y comprensión de
                        escritos técnicos en idioma inglés, presentando el
                        examen de inglés (TOEFL) y obteniendo el puntaje
                        requerido de 400 puntos como mínimo.
                      </p>
                    </li>
                  </ul>

                  <!--FIN DE REQUISITOS-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--FIN DEL ACCRODION PARA REQUISITOS-->

        <!--ACORDION DE DOCUMENTACION-->
        <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                <i class="large material-icons me-3"> library_books </i>
                Documentacion
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body pservices text-justify">
                <p class="text-title">Documentación de ingreso</p>
                <p class="text-card ">
                  Los estudiantes que deseen obtener el grado de Maestro en
                  Sistemas Computacionales ,deberán:
                </p>
                <hr />
                <ul>
                  <li>
                    <p class="card-text">
                      Presentar y desarrollar un proyecto de estudio individual
                      de carácter técnico, empresarial o docente para su tesis.
                    </p>
                  </li>
                  <li>
                    <p class="card-text">
                      Contar con la autorización de impresión de la tesis
                      emitida por el Jefe de la División de Estudios de Posgrado
                      e Investigación, a propuesta del jurado del examen.
                    </p>
                  </li>
                  <li>
                    <p class="card-text">
                      Demostrar el dominio de un segundo idioma, el cual deberá
                      acreditar mediante examen TOEFL, con un puntaje de 450
                      puntos.
                    </p>
                  </li>
                  <li>
                    <p class="card-text">
                      Acreditar todas las asignaturas y actividades académicas
                      del programa de Maestría en Sistemas Computacionales, y
                      presentar y aprobar el examen de grado correspondiente.
                    </p>
                  </li>
                  <li>
                    <p class="card-text">
                      Presentar evidencia de: desarrollo tecnológico, registro
                      de propiedad intelectual, desarrollo de software o
                      prototipos, patentes y participación en eventos
                      académicos.
                    </p>
                  </li>
                  <li>
                    <p class="card-text">
                      Contar con al menos dos artículos publicados en congresos
                      nacionales o internacionales y/o revistas indexadas
                      arbitradas.
                    </p>
                  </li>
                  <li>
                    <p class="card-text">
                      Realización de estancia en el sector público, privado o
                      social.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--FIN DOCUMENTACIÓN DE ADMICION-->
      </div>
      <!--FIN DE LA COLUMNA 1-->


    </div>
    <!--FNI DEL RENGLON-->

    <div class="row">
      <div class="col col2">
        <figure class="figure">
          <img src="../../../assets/images/interfaz/9A32SmkfgUYpvOkY_tdL1kRBztOqJtl1dkBvWch1moeE74fiwWq1pYCz7V3Q1cLslqxG86sqPhljrtHv8BB06UaGYEFI8fdl59LmYurbMVIyEG_LZIYP1WqLyZhUm3z5r5P679Q.jpg" class="figure-img img-fluid rounded" alt="...">
        </figure>
      </div>






  </div>
</div>
