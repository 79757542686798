<!--I MAIN CONTAINER -->
<div class="main-container">
  <!--CONTENEDOR DE COLUMNAS-->
  <div class="container">
    <!--INICIO DEL RENGLON-->
    <div class="row">
      <!--COLUMNA 1-->
      <div class="col">
        <!--ACORDION-->
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <!--ACORDION ITEM-->
          <div class="accordion-item">
              <h2 class="text-title mb-3 mt-4">
                  Características de la Tesis
              </h2>
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
              <span class="material-icons md-48 me-3">
                checklist
                </span>
                Lineamientos
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body ">
                <p class="text-justify">

                    La tesis desarrollada por los estudiantes de la 
                    Maestría en Sistemas Computacionales estará 
                    enmarcada dentro de la línea de generación y 
                    aplicación del conocimiento de Ingeniería de 
                    Software, o podrá generarse a partir de acuerdos 
                    o bases de concertación con instituciones, asociaciones
                    , organismos, industrias o empresas de los sectores de 
                    la sociedad.
                    La tesis es el documento que refleja, de manera 
                    sintética pero suficiente, el trabajo que el 
                    estudiante realiza individualmente durante su 
                    formación académica, y constituye el planteamiento, 
                    procedimiento y resultados de una investigación, 
                    de un desarrollo tecnológico o de un proyecto 
                    profesional, según sea el caso.

                </p>
                <hr>
                <div class="container-lista-lineamientos">
                    <ul>
                        <li>
                            <p class="text-justify">
                                Estar vinculados con las líneas de trabajo de la maestría.
                                
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Estar enfocado a desarrollo tecnológico.
                                
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Resolver un problema real y que esté vinculado con el sector productivo.
                                
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Participar en foros, congresos y revistas de reconocido prestigio nacionales e internacionales
                                
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Los proyectos serán aprobados por el consejo de posgrado.
                                
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                Deberán desarrollarse a la par con las asignaturas y concluirse en un período de dos años.
                                
                            </p>
                        </li>
                        <li>
                            <p class="text-justify">
                                En casos excepcionales y a juicio del Coordinador de Estudios de Posgrado, podrán ampliarse los períodos de ejecución y terminación de los proyectos.

                            </p>
                        </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
          <!--FIN DEL ACORDION ITEM-->
        </div>
        <!--FIN DEL ACRODION-->
      </div>

      <!--COLUMNA 2-->
      <div class="col">
        ​<div class="img-container">
          <img src="../../../assets/images/tesis.jpg" class="img-fluid img-thumbnail" alt="tesis">
        </div>
      </div>
    </div>
    <!--FIN DEL RENGLON -->
  </div>
  <!--CONTENEDOR DE COLUMNAS-->
</div>
<!--FIN MAIN CONTAINER-->
