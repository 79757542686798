<div class="accordion-cocntainer">
<div class="main-container pservices text-justify">

      <!--CONTENEDOR DE COLUMNAS-->
      <div class="container">
        <br>
        <img src="../../../assets/images/interfaz/9A32SmkfgUYpvOkY_tdL1kRBztOqJtl1dkBvWch1moeE74fiwWq1pYCz7V3Q1cLslqxG86sqPhljrtHv8BB06UaGYEFI8fdl59LmYurbMVIyEG_LZIYP1WqLyZhUm3z5r5P679Q.jpg">

         <!--CONTENEDIR DE REQUISITOS PARA LA OBTENCION DE GRADO-->
              <div class="container-costos">
                  <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item mt-5">
                          <h2 class="accordion-header" id="flush-headingOne">
                              <button class="accordion-button collapsed text-white " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                  <i class="material-icons me-3">payments</i>
                                  Costos
                              </button>
                          </h2>
                          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body">
                                <table class="table">
                                  <thead class="text-white">
                                    <tr>

                                      <th scope="col">Trámite</th>
                                      <th scope="col">Costos</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Inscripcion Semestral<br>
                                      </td>
                                      <td>
                                        1,000.00$MXN
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Cuota Semestral</td>
                                      <td>6,000.00$MXN</td>
                                    </tr>
                                  </tbody>
                                </table>
                                </div>

                          </div>
                      </div>
                  </div>
              </div>





              <!--CONTENEDIR DE REQUISITOS PARA EL EXAMEN DE GRADO-->
              <div class="container-fechas-importantes  ">
                  <div class="accordion accordion-flush" id="accordionFlushExample">
                      <div class="accordion-item mt-5">
                          <h2 class="accordion-header" id="flush-headingTwo">
                              <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                  <i class="material-icons me-3">
                                      event
                                  </i>
                                  Fechas importantes
                              </button>
                          </h2>
                          <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                              <div class="accordion-body">
                                <table class="table">
                                  <thead class="text-white">
                                    <tr>

                                      <th scope="col">Trámite</th>
                                      <th scope="col">Fecha de aplicación</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        Solicitud para examen de conocimientos y entrevista <br>
                                      </td>
                                      <td>
                                        Agosto 2021 (Día por definir)
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Inicio de clases</td>
                                      <td>Septiembre 2021 (Día por definir)</td>
                                    </tr>
                                    <tr>
                                      <td>Inscripciones</td>
                                      <td>Agosto 2021 (Día por definir</td>
                                    </tr>
                                    <tr>
                                      <td>Examen CENEVAL (Examen desde casa)

                                       </td>
                                      <td>
                                          Fecha de registro <br>
                                          Del 23 de agosto al 17 de octubre de 2021 <br>
                                          Fecha de aplicación <br>
                                          <br>
                                          Del 27 de noviembre de 2021 <br>
                                          (Información tomada de la página oficial de CENEVAL)
                                          https://www.ceneval.edu.mx/</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>


      </div>

</div>
  <!--FIN DEL CONTENEDOR PRINCIPAL-->

</div>
