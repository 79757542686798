<section id="team" class="pb-5">
  <div class="container">
    <h5 class="section-title h1 text-center">Primera Generación<h3>2018-2020</h3></h5>
    <div class="row">

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Arredondo Ríos Ricardo</h4>
                  <p class="card-text">
                    Tutor:
                    <a href="nucleo-academico">
                    Andrés Salas Nuñez <br /><br></a>
                    Tema de Tesis: Generación semi-automática de épicas, historias de usuario a partir de diagrama de procesos<br><br>
                    Director de tesis: Andrés Salas Núñez<br>

                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Díaz Díaz Álvarado Moises</h4>
                  <p class="card-text">
                    Tutor:
                    <a href="nucleo-academico">
                    Jaime Iván López Veyna <br /><br /></a>
                    Tema de Tesis: Aplicación móvil para la detección de la Depresión Postparto en el Estado de Zacatecas, México Utilizando Técnicas de Aprendizaje Automático<br><br>
                    Director de tesis: Jaime Iván López Veyna<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Ortíz Gonzalez Isbac</h4>
                  <p class="card-text">
                    Tutor:
                    <a href="nucleo-academico">
                    Edgar Esaúl Saucedo Becerra<br /><br></a>
                    Tema de Tesis: Sistema de geolocalización de apiarios<br><br>
                    Director de tesis: Edgar Esaúl Saucedo Becerra<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Padilla Dorado Eugenia</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    Andrés Salas Nuñéz<br /><br></a>
                    Tema de Tesis: Modelado predictivo de tendencias educativas en el municipio de Jerez Zacatecas<br><br>
                    Director de tesis: Andrés Salas Nuñéz<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Ruíz Martínez Jesús Enrique</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    Jaime Iván López Veyna<br /><br></a>
                    Tema de Tesis: Sistema de Análisis de deserción de la carrera de Sistemas Computacionales en el ITZ<br><br>
                    Director de tesis: Jaime Iván López Veyna<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Sánchez mendoza Francisco Samuel</h4>
                  <p class="card-text">
                    Tutor<a href="nucleo-academico">
                    Adrián Aguilar Caldera <br /><br></a>
                    Tema de Tesis: Módulo de Almacén Cloud ITZ<br><br>
                    Director de tesis: Adrián Aguilar Caldera<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Soto García Juan Carlos</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    Mariana Ortíz García <br /><br></a>
                    Tema de Tesis: Gestión de Proyectos para el desarrollo de aplicaciones móviles utilizando una metodología ágil híbrida<br><br>
                    Director de tesis: Mariana Ortíz García<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Vázquez Neri Ariana Yareli</h4>
                  <p class="card-text">
                    Tutor: <a href="nucleo-academico">
                    Jaime Iván López Veyna <br /></a>
                    Tema de Tesis: Diseño e implementación de juegos colaborativos mediante
                    m-Learning, para comunidades con situación de rezago educativo en el estado de Zacatecas<br><br>
                    Director de tesis: Jaime Iván López Veyna<br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-0 col-sm-6 col-sm-4">
        <div class="image-flip" ontouchstart="this.classList.toggle('hover');">
          <div class="mainflip">
            <div class="frontside">
              <div class="card">
                <div class="card-body text-center">
                  <p>
                    <img
                      class="img-fluid"
                      src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                      alt="card image"
                    />
                  </p>
                  <h4 class="card-title">Veyna Reyes Daniela</h4>
                  <p class="card-text">
                    Tutor: <br /><a href="nucleo-academico">
                    Alfredo García Castañón <br /></a>
                    Tema de Tesis: Diseño de Interfaz Satelital para la Geolocalización de vehículos en la Industria de la Construcción en la ciudad de Zacatecas<br><br>
                    Director de tesis: Alfredo García Castañón <br>
                  </p>
                  <a href="#" class="btn btn-primary btn-sm"
                    ><i class="fa fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
