import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requisitos-egreso',
  templateUrl: './requisitos-egreso.component.html',
  styleUrls: ['./requisitos-egreso.component.css']
})
export class RequisitosEgresoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
