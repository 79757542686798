<div class="container mt-5">
  <div class="accordion accordion-flush" id="accordionFlushExample">
    <div class="accordion-item mt-5 mb-5">
      <h2 class="accordion-header" id="flush-headingOne">
        <h2 class="text-title mb-3">Vinculación</h2>
        <button
          class="accordion-button collapsed text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#flush-collapseOne"
          aria-expanded="false"
          aria-controls="flush-collapseOne"
        >
          <i class="large material-icons me-3"> article </i>
          Información
        </button>
      </h2>
      <div
        id="flush-collapseOne"
        class="accordion-collapse collapse"
        aria-labelledby="flush-headingOne"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body">
          <p class="text-alling" style="text-align:justify" >
            Con el fin de proyectar al programa con empresas e instituciones privadas o públicas se realizan
            diversos mecanismos de vinculación: la participación en redes de colaboración, firma de acuerdos,
            estancias profesionales de alumnos y profesores, impartición de cursos de capacitación y profesores
            visitantes.
            Estas actividades de vinculación repercuten en mejores resultados del proceso formativo, ya que
            el alumno se enfrenta a problemas reales de las Empresas o Instituciones, logrando una transferencia
            de conocimiento y satisfacción personal al colaborar en la solución de problemas del ámbito social y
            empresarial.
            Durante sus estudios, el alumno realiza una estancia profesional en alguna institución pública o
            privada..
          </p>
        </div>
      </div>
      <br><br><br><br>
      <div>
        <h2>Convenios</h2>
        <div class>
          <a href="https://www.cimat.mx/"><img class="logo" src="https://www.cimat.mx/sites/all/themes/danland/logo.png" alt="CIMAT" /></a>
          <a href="https://cozcyt.gob.mx/"><img class="logo "src="https://cozcyt.gob.mx/wp-content/uploads/2018/07/COZCyT-2016-2021.png" alt="COZCYT" /></a>
        </div>
    </div>
  </div>
</div>
</div>

