<div
    class="container">

    <!--INICIO CONTAINER-LEFTH-->
    <div class="container-lefth mt-5">

        <!--INICIO DE LA COLUMNA-->
        <div class="col">

            <!--INICIO DE LOS ACCORDIONS-->
            <div class="accordion accordion-flush" id="accordionFlushExample">

                <!--INICIO DEL ACCORDION ITEM-->
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <span class="material-icons me-3">
                                drive_file_rename_outline
                            </span>
                            Sintesis del plan de estudio
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body pservice text-justify">
                            
                            
                            

                            <!--OBJETIVOS GENERALES Y ESPECIFICOS DEL PROGRAMA DE POSGRADOS-->
                            
                            <!--OBJETIVOS GENERALES-->
                                <h2 class="text-title">
                                    Objetivo General
                                </h2>
                                <p>
                                Formar profesionales a nivel posgrado orientados al 
                                desarrollo de actividades académicas y profesionales 
                                en el área de Sistemas Computacionales que integren 
                                habilidades para el desarrollo y aplicación de 
                                conocimiento técnico y científico utilizando 
                                tecnologías computacionales que den respuesta a las 
                                necesidades de las empresas locales, regionales y 
                                nacionales con apego a la ética profesional.

                                </p>

                                <hr>

                                <!--OBJETIVOS ESPECIFICOS-->
                                <h2 class="text-title">
                                    Objetivos Específicos
                                </h2>

                                <ul>
                                    <li>Generar proyectos de investigación con un enfoque multidisciplinario, orientados a fortalecer las capacidades científicas y tecnológicas y de innovación en la solución de problemas en el entorno local, nacional e internacional.</li>
                                    <li>Formar profesionistas capaces de aplicar conocimientos científicos y tecnológicos en el entorno laboral.</li>
                                    <li>Impulsar el desarrollo y generación de productos de investigación científica.</li>
                                    <li>Fortalecer el desarrollo de actividades académicas y profesionales en el área de sistemas computacionales.</li>
                                    <li>Participar en convocatorias emitidas por parte de CONACyT, PRODEP, PROSOFT y Gobiernos Federales, Estatales y Municipales que involucren al Instituto Tecnologico de Zacatecas en conjunto con empresas.</li>
                                    <li>Fomentar la transferencia de tecnología entre universidades e instituciones gubernamentales</li>
                                    <li>Actualizar profesionales en el área de sistemas computacionales a fin de aplicar sus conocimientos en el ámbito laboral con ética profesional.</li>
                                    <li> Encauzar el desempeño profesional hacia una mayor comprensión de las necesidades del sector productivo, y alentar su participación en el mismo.</li>
                                </ul>
                                <hr>

                                <!--METAS DEL PLAN DE ESTUDIO-->
                                <h2 class="text-title">
                                    Metas
                                </h2>

                                <p>
                                    Posicionar la Maestría en Sistemas Computacionales 
                                    en el estado de Zacatecas como la principal opción 
                                    para estudiar y actualizarse en el área de sistemas 
                                    computacionales, contribuyendo a las demandas y 
                                    requerimientos locales, regionales, nacionales e 
                                    sinternacionales, de manera que:
                                </p>

                                <ul>
                                    <li>Se favorezca la formación de recursos humanos calificados.</li>
                                    <li>Se impulse la innovación y el desarrollo tecnológico.</li>
                                    <li>Se promueva y fortalezca la vinculación con asociaciones, empresas, organismos e instituciones públicas y privadas de la sociedad zacatecana.</li>
                                    <li>Se participe activamente en la solución de problemas para contribuir al desarrollo socioeconómico del país.</li>
                                    <li>Se facilite la inserción de los egresados en empresas, organismos e instituciones públicas y privadas de la región.</li>
                                    <li>Se impulse la participación en convocatorias para la obtención de recursos que permita fortalecer la infraestructura del programa.</li>
                                </ul>

                                <hr>

                                <!--MISION-->
                                <h2 class="text-title">
                                    Misión
                                </h2>

                                <p>
                                    Formar profesionistas de excelencia en el área de sistemas 
                                    computacionales a nivel posgrado, capaces de resolver problemas 
                                    aplicando el conocimiento en forma innovadora para satisfacer 
                                    necesidades planteadas en los sectores público y privado apegados a una sólida ética profesional.
                                </p>

                                <hr>

                                <!--VISION-->
                                <h2 class="text-title">
                                    Visión
                                </h2>

                                <p>
                                    Ser un programa de excelencia a nivel 
                                    nacional en la generación de profesionistas 
                                    en tecnologías computacionales como factor 
                                    de cambio para el progreso del país avalado 
                                    por el prestigio de su personal académico.
                                </p>

                                <hr>



                          


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <!--INICIO CONTAINER-RIGTH-->
    <div class="container-rigth">
        <div class="col">

            <!--CONTAINER BASICS ASIGNATURES-->
            <div class="main-container-TabelBasicAsignature mb-5">

                <!--TABLE TITLE-->
                <div class="container-titleTable mb-2">
                    <h2 class="text-title">
                        Asignaturas Básicas
                    </h2>
                </div>

                <!--TABLE BASICS ASIGNATURES-->
                <div class="table-basics-asignatures">
                    <!--INICIO DE LA TABLA-->
                    <table class="table">
                        <thead class="text-white">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Materia</th>
                                <th scope="col">Temario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">MPSCO-0105</th>
                                <td>Ingenieria de Software</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0106</th>
                                <td>Tecnologías de porgramación</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0107</th>
                                <td>Modelo conceptual de aplicaciones web</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0109</th>
                                <td>Bases de datos</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            

             <!--CONTAINER OPTATIVES ASIGNATURES-->
             <div class="main-container-optativesAsignatures">

                <!--TABLE TITLE-->
                <div class="container-titleTable mb-2">
                    <h2 class="text-title">
                        Asignaturas Optativas
                    </h2>
                </div>

                <!--TABLE OPTATIVES ASIGNATURES-->
                <div class="table-optatives-asignatures">
                    
                    <!--INICIO DE LA TABLA-->
                    <table class="table">
                        <thead class="text-white">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Materia</th>
                                <th scope="col">Temario</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">MPSCO-0217</th>
                                <td>Lenguajes Web</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0220</th>
                                <td>Programación de dispositivos moviles</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0222</th>
                                <td>Técnicas avanzadas de programación</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0223</th>
                                <td>Arquitectura Orienteada a Servicios</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0225</th>
                                <td>Ingeniería de software orientada a procesos</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">MPSCO-0228</th>
                                <td>INterfaces humano computadora</td>
                                <td>
                                    <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>


            <!--CONTAINER TESIS SEMINAR-->
            <div class="main-container-tesisSeminar">

                            <!--TABLE TITLE-->
                            <div class="container-titleTable mb-2">
                                <h2 class="text-title">
                                    Seminario de Tésis
                                </h2>
                            </div>
            
                            <!--TABLE TESIS SEMINAR-->
                            <div class="table-tesis-seminar">
                                <!--INICIO DE LA TABLA-->
                                <table class="table">
                                    <thead class="text-white">
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Materia</th>
                                            <th scope="col">Temario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">MPSCO-0301</th>
                                            <td>Seminario I</td>
                                            <td>
                                                <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">MPSCO-0302</th>
                                            <td>Seminario II</td>
                                            <td>
                                                <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">MPSCO-0303</th>
                                            <td>Seminario III</td>
                                            <td>
                                                <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">MPSCO-304</th>
                                            <td>Tesis</td>
                                            <td>
                                                <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">MPSCO-0225</th>
                                            <td>Ingeniería de software orientada a procesos</td>
                                            <td>
                                                <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">MPSCO-0228</th>
                                            <td>INterfaces humano computadora</td>
                                            <td>
                                                <a download="nombre_archivo" href="url_del_fichero">Anchor text</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
            
                            </div>
            </div>


            <!--CONTAINER PLAN DE ESTUDIOS-->
            <div class="main-container-planDeEstudios pservice text-justify">

                <h2 class="text-title mt-5">
                    Sintésis del plan de estudio
               </h2>
   
               <p>
                   Para cursar el programa se deberán cubrir satisfactoriamente 100 créditos del plan de estudios en un periodo de dos años. 
                   Las asignaturas se dividen en Básicas y Optativas, las primeras se refieren a asignaturas que son fundamentales en el área 
                   de las ciencias computacionales, y representan 24 créditos SATCA. Por otro lado las Optativas son de actualización, de 
                   especialización o de innovación y representan obligatoriamente 24 créditos SATCA.  La estructura genérica se muestra en 
                   la Tabla 1.
               </p>
       
               <div class="container-img-genericEstructur mt-5">
                   <img src="../../../assets/images/plan-estudios-sintesis.png" alt="..." class="img-thumbnail" id="img-estructura-general">
               </div>

            </div>

            
            <!--CONTAINER RETICULA CUATRO SEMESTRES-->
            <div class="main-container-mapaCurricular pservice text-justify">

                            <h2 class="text-title mb-5">
                                Mapa curricular a cuatro semestres
                           </h2>
                           
                           <p>  El mapa curricular a cuatro semestres se muestra en la Tabla 1 se puede observar que no existe una serialización
                                vertical u horizontal de las asignaturas del programa, lo cual permite una flexibilidad 
                                al estudiante sin embargo es recomendable cursar las materias siguiendo el orden marcado por el 
                                semestre asignado.

                            <br>
                            <br>

                            El mapa curricular a cuatro semestres se muestra en la Tabla 1 se puede observar que no existe una 
                                                        serialización vertical u horizontal de las asignaturas del programa, lo cual permite una flexibilidad 
                                                        al estudiante sin embargo es recomendable cursar las materias siguiendo el orden marcado por el semestre 
                                                        asignado.
                           </p>



                           <div class="container-img-genericEstructur">
                               <img src="../../../assets/images/mapa-curricular-cuatro-semestres.png" alt="..." class="img-thumbnail" id="img-mapa-curricular">
                           </div>
            
            </div>
                                 
                       
                       
           


        </div>

    </div>


</div>
