<!--Inicio del contenedor principal-->
<div
    class="main-conteiner">
    <!--Inicio del contenedor de columnas-->
    <div
        class="container">
        <!--I Row 1-->
        <div
            class="row">
            <!--I Col 1-->
            <div class="col col1">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item ">
                        <h2 class="accordion-header pservices text-justify" id="flush-headingOne">
                            <h2 class="txt-title mt-5 mb-3">
                                Requisitos de permanencia en el programa
                            </h2>
                            <hr class="mt-3">
                            <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <i class="large material-icons me-3">
                                    done
                                </i>
                                Requisitos
                            </button>
                        </h2>
                        <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body pservices text-justify">
                                <p>
                                    La permanencia de un estudiante en el programa de Maestría en
                                                      Sistemas Computacionales dependerá de los siguientes aspectos:
                                </p>
                                <hr/>
                                <div class="container-lista-requisitos-permanencia">
                                    <ul>
                                        <li>
                                            <p class="text-justify">
                                                Acatar todas las disposiciones reglamentarias del
                                                                        Tecnológico Nacional de México, para su buen
                                                                        funcionamiento y mejor aprovechamiento y,
                                                                        consecuentemente, se evitará ser sujeto de sanciones por
                                                                        parte de las autoridades competentes.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Inscribirse en cada periodo, durante el programa, hasta
                                                                        la obtención de grado. Después de los periodos
                                                                        reglamentarios, el estudiante deberá inscribirse aunque
                                                                        no cuente con carga académica asignada en ese periodo.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Dedicar tiempo completo a sus estudios cuando el
                                                                        programa así lo requiera.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Acreditar las asignaturas con una calificación mínima de
                                                                        setenta (70), pero el promedio mínimo global requerido
                                                                        para la obtención del grado de Maestro en Sistemas
                                                                        Computacionales será de ochenta (80).
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                No reprobar dos veces una misma asignatura o dos
                                                                        asignaturas diferentes.
                                            </p>
                                        </li>

                                        <li>
                                            <p class="text-justify">
                                                Presentar en tiempo y forma los avances del proyecto de
                                                                        tesis cuando los requiera el Consejo de Posgrado.
                                            </p>
                                        </li>
                                        <li>
                                            <p class="text-justify">
                                                Incumplir cualquiera de estas u otras disposiciones
                                                                        emitidas por las instancias educativas al efecto, será
                                                                        causa para la baja definitiva del estudiante.
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <hr>
                                <p class="card-text">
                                    Todo estudiante de la Maestría en 
                                                        Sistemas Computacionales que haya 
                                                        cursado al menos un semestre del 
                                                        programa podrá solicitar su baja 
                                                        temporal al Jefe de la División 
                                                        de Estudios de Posgrado e 
                                                        Investigación, en todas las 
                                                        asignaturas por un periodo, 
                                                        para lo cual presentará dicha 
                                                        solicitud por escrito, avalada por
                                                        su Director de Tesis.
                                    <br>
                                    Cuando el estudiante decida no continuar
                                                        sus estudios podrá solicitar la 
                                                        certificación de las asignaturas cursadas; 
                                                        las asignaturas no aprobadas no se computarán 
                                                        para efectos del promedio general.
                                    <br>
                                    El Departamento de Servicios Escolares de la 
                                                        institución será responsable de la inscripción 
                                                        y reinscripción de estudiantes en las fechas y 
                                                        periodos establecidos por la División de Estudios 
                                                        de Posgrado e Investigación, así como de la 
                                                        certificación de estudios, el resguardo de la 
                                                        documentación correspondiente y de la actualización 
                                                        del registro histórico de cada estudiante.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--F Col 1-->
        </div>
        <!--F Row 1-->

        <!--ROW 2-->
        <div class="row">
            <!--I Col 2-->
            <div class="col col2">
                <figure class="figure">
                    <img src="../../../assets/images/interfaz/2.jpg" class="figure-img img-fluid rounded" alt="...">
                </figure>
            </div>
            <!--F Col 2-->
        </div>
        <!--ROW 2-->

    </div>
    <!--Final del contenedor de columnas-->
</div>
<!--Fin del contenedor principal-->
