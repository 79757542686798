<div class="header">
  <app-header></app-header>
</div>

<div class="navigation d-flex justify-content-center">
    <app-navigation></app-navigation>
  </div>

<div class="main-container">
  <router-outlet></router-outlet>
</div>


