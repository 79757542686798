import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caracteristicas-tesis',
  templateUrl: './caracteristicas-tesis.component.html',
  styleUrls: ['./caracteristicas-tesis.component.css']
})
export class CaracteristicasTesisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
