<div class="main-container d-flex">


    


    <div class="table-container">

        <div class="header-container mb-5 text-white">
            <h1 class="text-title">
                Normas y estatutos
            </h1>
        </div>

        <!--INICIO DE TABLA-->
        <table class="table">

            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Nombre</th>
                    <th scope="col"></th>
                    <th scope="col">Enlace</th>
                    

                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td colspan="2">
                        Politicas de calidad
                    <td>
                        <a href="http://mapaches.itz.edu.mx/~sgc/sgc/">Politicas de calidad</a>
                    </td>

                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td colspan="2">
                        Lineamiento de operación de posgrados</td>
                    <td>
                        <a href="../../../assets/documents/LineamientosOperacionPosgrados.pdf" download="LineamientosOperacionPosgrado.pdf">Lineamiento-de-operaciones.pdf</a>
                    </td>

                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td colspan="2">Código de ética de las personas y servidoras públicas del Gobierno Federal</td>
                    <td>
                        <a href="https://zacatecas.tecnm.mx/pdf/docanexo/SFP-CE-2019_C%C3%B3digo_de_%C3%89tica.pdf">Codigo_ética.pdf</a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td colspan="2">
                        Código de Conducta del Tecnológico Nacional de México</td>
                    <td>
                        <a href="https://zacatecas.tecnm.mx/pdf/docanexo/SFP-CE-2019_C%C3%B3digo_de_%C3%89tica.pdf">Codigo_conducta.pdf</a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td colspan="2">
                        Sistema de Gestión de Igualdad de Género y No Discriminación (SGIG)</td>
                    <td>
                        <a href="https://www.tecnm.mx/?vista=Sistema_Gestion_Igualdad">Sistema_gestion_igualdad.pdf</a>
                    </td>

                </tr>
                <tr>
                    <th scope="row">6</th>
                    <td colspan="2">
                        Reglamento Interno Maestría en Sistemas Computacionales</td>
                    <td>
                        <a href="../../../assets/documents/6. Reglamentomsc.pdf" download="Reglamentomsc.pdf">Reglamento_interno_MSC.pdf</a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">7</th>
                    <td colspan="2">
                        Manual para el otorgamiento de Licencias Beca Comisión</td>
                    <td>
                        <a href="../../../assets/documents/7. becacomision.pdf" download="becascomision.pdf">Manual.pdf</a>
                    </td>
                </tr>
                <tr>
                    <th scope="row">8</th>
                    <td colspan="2">Políticas Académicas del Periodo Sabático</td>
                    <td>
                        <a href="../../../assets/documents/sabatico.pdf">Sabatico.pdf</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <!--FIN DE TABLA-->
    </div>

</div>
