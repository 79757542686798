<div class="main-container d-flex justify-content-center ">
    <div class="row">
        <div class="col">
            <div class="accordion accordion-flush" id="accordionFlushExample">

                <div class="accordion-item">
                    <h2 class="text-title mb-3">

                        Becas
                    </h2>
                    <hr>

                    <h2 class="accordion-header mt-4 " id="flush-headingOne">
                        <button class="accordion-button collapsed text-white" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <span class="material-icons me-3">
                                check
                            </span>
                            Requisitos
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body pservice text-justify ">
                                <p>

                                    Todos los candidatos aceptados al programa de maestría tendrán la posibilidad de ser postulados para la obtención de una beca Conacyt
                                                                                                Requisitos para ser postulado a una beca Conacyt:
                                </p>

                                <ul>
                                    <li>
                                        <p>
                                            Estar inscrito en el programa de la Maestría en Sistemas Computacionales

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Contar con el registro del CVU

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Poseer un promedio mínimo de 80

                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Entregar la documentación requerida por la convocatoria correspondiente
                                        </p>
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </div>
